import React from "react";

// Components
import { PersonModal } from "components/PersonModal/PersonModal";

// Hooks
import { useProfileAddressModal } from "./hooks/useProfileAddressModal";

export const ProfileAddressModal = ({
    profileInfo,
    setProfileState,
    setShouldShowProfileAddressModal,
    shouldShowProfileAddressModal,
}) => {
    /**
     * Custom Hooks
     */

    const {
        currentSection,
        handleChange,
        handleSubmit,
        headerText,
        headerTextOptions,
        loading,
        setCurrentSection,
        setHeaderText,
        userStaging,
    } = useProfileAddressModal({ profileInfo, setProfileState });

    /**
     * End Hooks
     */

    return (
        <PersonModal
            config={{
                shouldEnableStateFieldToggle: true,
                shouldHideFormAddressFormatterBanner: true,
                shouldHideFormAddressPreview: true,
                shouldHideFormNameToggle: true,
                shouldHideFormTitleField: true,
                shouldShowFormBackButton: false,
                shouldShowFormCountryField: true,
            }}
            currentSection={currentSection}
            headerText={headerText}
            headerTextOptions={headerTextOptions}
            loading={loading}
            onChange={handleChange}
            onCloseModal={() => setShouldShowProfileAddressModal(false)}
            onSubmit={handleSubmit}
            personStaging={userStaging}
            setCurrentSection={setCurrentSection}
            setHeaderText={setHeaderText}
            shouldReverseFullName={false}
            shouldShow={shouldShowProfileAddressModal}
        />
    );
};
