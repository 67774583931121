import React from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { ProfileDeleteAccountModalConfirm } from "./components/ProfileDeleteAccountModalConfirm";
import { ProfileDeleteAccountModalVerify } from "./components/ProfileDeleteAccountModalVerify";
import { ProfileDeleteAccountModalPhoneCodeVerify } from "./components/ProfileDeleteAccountModalPhoneCodeVerify";

// CSS
import styles from "./profile-delete-account-modal.module.scss";

// Hooks
import { useProfileDeleteAccountModal } from "./hooks/useProfileDeleteAccountModal";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileDeleteAccountModal = (props) => {
    const {
        setShouldShowProfileDeleteAccountModal,
        shouldShowProfileDeleteAccountModal,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        callbacks,
        currentSection,
        handleConfirmationToggle,
        handleDeleteAccount,
        handleEmailLogin,
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handlePhoneLogin,
        handleChangeConfirmVerificationCodeInput,
        handleSubmitConfirmVerifcationCode,
        hasConfirmedDeleteIntent,
        isEmail,
        isEmailCode,
        isPhone,
        isSocial,
        loading,
        phoneNumber,
        resendVerificationCode,
        setVerificationMethod,
        setHasConfirmedDeleteIntent,
        setCurrentSection,
        setShouldShowErrorState,
        shouldShowErrorState,
        verificationCode,
        verificationMethod,
    } = useProfileDeleteAccountModal(props);

    /**
     * Constants
     */

    const isOnConfirmSection = currentSection === "confirm";

    /**
     * End Hooks
     */

    function getHeaderText(section) {
        return section === "phoneCodeVerify"
            ? t("verify_your_phone_number", { ns: "common" })
            : t("verify_your_account", { ns: "common" });
    }

    function getHeaderSubText(section) {
        return section === "verify"
            ? t("before_proceed_verify_account", { ns: "common" })
            : t("we_have_texted_verification_code", { ns: "common" });
    }

    function onCloseModal() {
        setShouldShowProfileDeleteAccountModal(false);
        setShouldShowErrorState(false);
        setCurrentSection("verify");
        setHasConfirmedDeleteIntent(false);
    }

    function renderCurrentSection() {
        return {
            confirm: (
                <ProfileDeleteAccountModalConfirm
                    {...props}
                    hasConfirmedDeleteIntent={hasConfirmedDeleteIntent}
                    handleConfirmationToggle={handleConfirmationToggle}
                    handleDeleteAccount={handleDeleteAccount}
                    loading={loading.confirm}
                    setCurrentSection={setCurrentSection}
                    setHasConfirmedDeleteIntent={setHasConfirmedDeleteIntent}
                />
            ),
            phoneCodeVerify: (
                <ProfileDeleteAccountModalPhoneCodeVerify
                    handleChangeConfirmVerificationCodeInput={
                        handleChangeConfirmVerificationCodeInput
                    }
                    handleSubmitConfirmVerifcationCode={
                        handleSubmitConfirmVerifcationCode
                    }
                    loading={loading.phoneCodeVerify}
                    resendVerificationCode={resendVerificationCode}
                    shouldShowErrorState={shouldShowErrorState}
                    verificationCode={verificationCode}
                />
            ),
            verify: (
                <ProfileDeleteAccountModalVerify
                    {...props}
                    callbacks={callbacks}
                    handleEmailLogin={handleEmailLogin}
                    handleChangePhoneNumber={handleChangePhoneNumber}
                    handleClearPhoneNumber={handleClearPhoneNumber}
                    handlePhoneLogin={handlePhoneLogin}
                    loading={loading.phoneCodeGenerate}
                    isEmail={isEmail}
                    isEmailCode={isEmailCode}
                    isSocial={isSocial}
                    isPhone={isPhone}
                    phoneNumber={phoneNumber}
                    setVerificationMethod={setVerificationMethod}
                    setCurrentSection={setCurrentSection}
                    verificationMethod={verificationMethod}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            centered="true"
            className={styles["profile-account-delete-modal"]}
            cypressTestId="profile-account-delete-modal"
            cypressCloseButtonTestId="profile-account-delete-modal-close-button"
            onHide={onCloseModal}
            show={shouldShowProfileDeleteAccountModal}
            dialogClassName={isOnConfirmSection && styles["confirm-dialog"]}
            headerText={getHeaderText(currentSection)}
            headerSubText={getHeaderSubText(currentSection)}
            shouldHideHeader={isOnConfirmSection}
            shouldHideHeaderBorder={isOnConfirmSection}
            onClose={onCloseModal}
            size="md"
        >
            {renderCurrentSection()}
        </Modal>
    );
};
