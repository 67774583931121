import { useContext, useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Hooks
import { useNestedFormState } from "hooks/forms/useNestedFormState";

// Localization
import { useTranslation } from "localization/localization";

export const useProfileAddressModal = ({ profileInfo, setProfileState }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Constants
     */

    const headerTextOptions = {
        addressFormatter: "",
        addressManual: "",
        confirm: "",
        form: t("edit_your_address", { ns: "common" }),
        name: "",
    };

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        PersonModalNavigation.FORM
    );
    const [headerText, setHeaderText] = useState(headerTextOptions.form);
    const {
        formState: userStaging,
        handleChange,
        setFormState: setUserStaging,
    } = useNestedFormState({
        address: profileInfo?.address || {
            city: "",
            line1: "",
            line2: "",
            state: "",
            zipcode: "",
            country: "",
        },
        firstName: profileInfo?.firstName,
        lastName: profileInfo?.lastName,
    });
    const [loading, setLoading] = useState({
        form: false,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        if (profileInfo) {
            setUserStaging({
                address: profileInfo?.address || {
                    city: "",
                    line1: "",
                    line2: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
                firstName: profileInfo?.firstName,
                lastName: profileInfo?.lastName,
            });
        }
    }, [profileInfo, setUserStaging]);

    /**
     * End Hooks
     */

    function handleSubmit(e) {
        e.preventDefault();
        setLoading((prev) => ({
            ...prev,
            form: true,
        }));
        SandboxxRestAPI.updateUserAddress(
            userStaging,
            onUpdateUserAddressSuccess,
            onUpdateUserAddressFailure,
            onUpdateUserAddressFailure
        );
    }

    function onUpdateUserAddressFailure({ message }) {
        setLoading((prev) => ({
            ...prev,
            updateUserAddress: false,
        }));
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onUpdateUserAddressSuccess(res) {
        setLoading((prev) => ({
            ...prev,
            form: false,
        }));
        setProfileState((prev) => ({
            ...prev,
            profileInfo: {
                address: res.address,
                firstName: res.firstName,
                fullName: res.fullName,
                lastName: res.lastName,
            },
        }));
        window.location.reload();
    }

    return {
        currentSection,
        handleChange,
        handleSubmit,
        headerText,
        headerTextOptions,
        loading,
        setCurrentSection,
        setHeaderText,
        userStaging,
    };
};
