import React from "react";
import PropTypes from "prop-types";

// Components
import { PersonModal } from "components/PersonModal/PersonModal";

// Hooks
import { useLettersComposeSenderModal } from "./hooks/useLettersComposeSenderModal";

export const LettersComposeSenderModal = ({
    fetchAddOns,
    setShouldShowSenderModal,
    sender,
    setSender,
    shouldShowSenderModal,
}) => {
    /**
     * Custom Hooks
     */

    const {
        currentSection,
        headerText,
        headerTextOptions,
        handleChange,
        handleSubmit,
        loading,
        senderStaging,
        setCurrentSection,
        setHeaderText,
    } = useLettersComposeSenderModal({
        fetchAddOns,
        sender,
        setSender,
        setShouldShowSenderModal,
    });

    /**
     * End Hooks
     */

    return (
        <PersonModal
            config={{
                shouldEnableStateFieldToggle: true,
                shouldHideFormAddressFormatterBanner: true,
                shouldHideFormAddressPreview: true,
                shouldHideFormNameToggle: true,
                shouldHideFormTitleField: true,
                shouldShowFormBackButton: false,
                shouldShowFormCountryField: true,
            }}
            currentSection={currentSection}
            headerText={headerText}
            headerTextOptions={headerTextOptions}
            loading={loading}
            onChange={handleChange}
            onCloseModal={() => setShouldShowSenderModal(false)}
            onSubmit={handleSubmit}
            personStaging={senderStaging}
            setCurrentSection={setCurrentSection}
            setHeaderText={setHeaderText}
            shouldReverseFullName={false}
            shouldShow={shouldShowSenderModal}
        />
    );
};

LettersComposeSenderModal.propTypes = {
    fetchAddOns: PropTypes.func.isRequired,
    setShouldShowSenderModal: PropTypes.func.isRequired,
    sender: PropTypes.object.isRequired,
    setSender: PropTypes.func.isRequired,
    shouldShowSenderModal: PropTypes.bool.isRequired,
};
