import { useContext, useEffect, useState } from "react";

// Context
import { DepsContext } from "context/deps";
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import {
    generateProfileUpdateDiff,
    generateProfileUpdateMethods,
    generateUserStaging,
} from "../utils";
import { isAddressProperty } from "utils/userUtils";

// Validation
import { hasValidName } from "../validate";

export const useProfileFields = ({
    handleShowPersonaSelectionModal,
    onProfileUpdateSuccess,
    profileInfo,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);
    const { fetchDepsLocations } = useContext(DepsContext);

    /**
     * useState
     */

    const [isEditing, setIsEditing] = useState(false);
    const [userStaging, setUserStaging] = useState({});

    /**
     * useEffect
     */

    // Set state user to props user when props user changes
    useEffect(() => {
        if (profileInfo) {
            setUserStaging(generateUserStaging(profileInfo));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileInfo]);

    // Re-fetch DEP locations when branchId changes
    useEffect(() => {
        if (userStaging.branchId) {
            fetchDepsLocations({ branchId: userStaging.branchId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStaging.branchId]);

    useEffect(() => {
        if (userStaging.triggerSave) {
            updateUserProperties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStaging.triggerSave]);

    /**
     * End Hooks
     */

    function handleChange(e) {
        const { name, value } = e.target;
        isAddressProperty(name)
            ? setUserStaging((prev) => ({
                  ...prev,
                  address: { ...prev.address, [name]: value },
              }))
            : setUserStaging((prev) => ({
                  ...prev,
                  [name]: value,
              }));
    }

    function handleEditEnd(e, { shouldValidate }) {
        e.preventDefault();
        if (!shouldValidate) {
            // If the user hits cancel when editing...
            setIsEditing(false);
            setUserStaging(generateUserStaging(profileInfo));
        } else {
            if (!validateProfileAttributes()) {
                return;
            }
            // If the user hits save when editing...
            updateUserProperties();
        }
    }

    function handleOpenPersonaSelectionModal() {
        handleShowPersonaSelectionModal(true);
    }

    function updateUserProperties() {
        const newValues = generateProfileUpdateDiff(userStaging, profileInfo);
        const methods = generateProfileUpdateMethods(userStaging);
        Promise.all(
            Object.keys(newValues).map((property) => {
                return methods[property]();
            })
        )
            .then(onProfileUpdateSuccess)
            .catch(onProfileUpdateFailure);
    }

    function handleEditStart() {
        setIsEditing(true);
    }

    function onProfileUpdateFailure({ message }) {
        showNotification({
            text: message,
            type: "warning",
        });
        setIsEditing(false);
    }

    function validateProfileAttributes() {
        if (!hasValidName(userStaging)) {
            showNotification({
                text: t(
                    "fields.validate_profile_attributes_name_presence_error",
                    { ns: "profile" }
                ),
                type: "warning",
            });
            return false;
        } else if (
            userStaging.address?.line1?.length > 40 ||
            userStaging.address?.line2?.length > 40
        ) {
            showNotification({
                text: t(
                    "fields.validate_profile_attributes_address_length_error",
                    { ns: "profile" }
                ),
                time: 10000,
                type: "warning",
            });
            return false;
        }
        return true;
    }

    function toggleFormEditState(e, config) {
        e.preventDefault();
        isEditing ? handleEditEnd(e, config) : handleEditStart(config);
    }

    return {
        handleOpenPersonaSelectionModal,
        handleChange,
        isEditing,
        toggleFormEditState,
        userStaging,
    };
};
