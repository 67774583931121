import React, { useMemo, useState } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";

// CSS
import signInStyles from "scenes/SignIn/sign-in.module.scss";
import styles from "./profile-ship-date-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { generateShipDateSelectDefaults } from "./utils";
import { generateShipDateObject } from "utils/userUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const ProfileShipDateModal = ({
    handleShowShipDateModal,
    onProfileUpdateSuccess,
    profileInfo,
    shouldShowShipDateModal,
    showNotification,
}) => {
    const { basicTrainingShipDate } = profileInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);
    const [shipDate, setShipDate] = useState(
        generateShipDateObject(basicTrainingShipDate)
    );

    /**
     * Constants
     */

    const hasSelectedDate = shipDate?.day && shipDate?.month && shipDate?.year;
    const selectDefaults = useMemo(
        () => generateShipDateSelectDefaults(basicTrainingShipDate),
        [basicTrainingShipDate]
    );

    /**
     * End Hooks & Constants
     */

    function handleSelectDate(e) {
        const { name, value } = e.target;
        setShipDate((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function handleSubmit() {
        if (hasSelectedDate) {
            setIsLoading(true);
            const { day, month, year } = shipDate;
            const formattedShipDate = `${year}-${month}-${day}`;
            SandboxxRestAPI.setUserShipDate(
                { baseId: "", shipDate: formattedShipDate },
                onSetShipDateSuccess,
                onSetShipDateFailure,
                onSetShipDateFailure
            );
        }
    }

    function onCloseModal() {
        handleShowShipDateModal(false);
    }

    function onSetShipDateFailure(res) {
        setIsLoading(false);
        showNotification({
            text: res.message,
            type: "warning",
        });
    }

    function onSetShipDateSuccess() {
        onProfileUpdateSuccess();
        localStorage.setItem("shouldShowProfileUpdateSuccess", true);
    }

    return (
        <Modal
            centered="true"
            className={styles["profile-ship-date-modal"]}
            cypressTestId="profile-ship-date-modal"
            headerText={t("update_your_ship_date", { ns: "profile" })}
            headerSubText={t("keep_your_profile_up_to_date", { ns: "profile" })}
            onClose={onCloseModal}
            onHide={onCloseModal}
            show={shouldShowShipDateModal}
            size="md"
        >
            <div className={styles["select-date"]}>
                <div className={styles.inputs}>
                    <select
                        className={cx(
                            signInStyles.form,
                            "select",
                            styles.select
                        )}
                        id="ship-date-month"
                        name="month"
                        onChange={handleSelectDate}
                    >
                        <option
                            value=""
                            disabled
                            selected={selectDefaults.month.empty}
                        >
                            {t("month", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.january}
                            value="01"
                        >
                            {t("january", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.february}
                            value="02"
                        >
                            {t("february", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.march}
                            value="03"
                        >
                            {t("march", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.april}
                            value="04"
                        >
                            {t("april", { ns: "date_time" })}
                        </option>
                        <option selected={selectDefaults.month.may} value="05">
                            {t("may", { ns: "date_time" })}
                        </option>
                        <option selected={selectDefaults.month.june} value="06">
                            {t("june", { ns: "date_time" })}
                        </option>
                        <option selected={selectDefaults.month.july} value="07">
                            {t("july", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.august}
                            value="08"
                        >
                            {t("august", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.september}
                            value="09"
                        >
                            {t("september", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.october}
                            value="10"
                        >
                            {t("october", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.november}
                            value="11"
                        >
                            {t("november", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.month.december}
                            value="12"
                        >
                            {t("december", { ns: "date_time" })}
                        </option>
                    </select>
                    <select
                        className={cx(
                            signInStyles.form,
                            "select",
                            styles.select
                        )}
                        id="ship-date-day"
                        name="day"
                        onChange={handleSelectDate}
                    >
                        <option
                            value=""
                            disabled
                            selected={selectDefaults.day.empty}
                        >
                            {t("day", { ns: "date_time" })}
                        </option>
                        <option selected={selectDefaults.day["1"]} value="01">
                            1
                        </option>
                        <option selected={selectDefaults.day["2"]} value="02">
                            2
                        </option>
                        <option selected={selectDefaults.day["3"]} value="03">
                            3
                        </option>
                        <option selected={selectDefaults.day["4"]} value="04">
                            4
                        </option>
                        <option selected={selectDefaults.day["5"]} value="05">
                            5
                        </option>
                        <option selected={selectDefaults.day["6"]} value="06">
                            6
                        </option>
                        <option selected={selectDefaults.day["7"]} value="07">
                            7
                        </option>
                        <option selected={selectDefaults.day["8"]} value="08">
                            8
                        </option>
                        <option selected={selectDefaults.day["9"]} value="09">
                            9
                        </option>
                        <option selected={selectDefaults.day["10"]} value="10">
                            10
                        </option>
                        <option selected={selectDefaults.day["11"]} value="11">
                            11
                        </option>
                        <option selected={selectDefaults.day["12"]} value="12">
                            12
                        </option>
                        <option selected={selectDefaults.day["13"]} value="13">
                            13
                        </option>
                        <option selected={selectDefaults.day["14"]} value="14">
                            14
                        </option>
                        <option selected={selectDefaults.day["15"]} value="15">
                            15
                        </option>
                        <option selected={selectDefaults.day["16"]} value="16">
                            16
                        </option>
                        <option selected={selectDefaults.day["17"]} value="17">
                            17
                        </option>
                        <option selected={selectDefaults.day["18"]} value="18">
                            18
                        </option>
                        <option selected={selectDefaults.day["19"]} value="19">
                            19
                        </option>
                        <option selected={selectDefaults.day["20"]} value="20">
                            20
                        </option>
                        <option selected={selectDefaults.day["21"]} value="21">
                            21
                        </option>
                        <option selected={selectDefaults.day["22"]} value="22">
                            22
                        </option>
                        <option selected={selectDefaults.day["23"]} value="23">
                            23
                        </option>
                        <option selected={selectDefaults.day["24"]} value="24">
                            24
                        </option>
                        <option selected={selectDefaults.day["25"]} value="25">
                            25
                        </option>
                        <option selected={selectDefaults.day["26"]} value="26">
                            26
                        </option>
                        <option selected={selectDefaults.day["27"]} value="27">
                            27
                        </option>
                        <option selected={selectDefaults.day["28"]} value="28">
                            28
                        </option>
                        <option selected={selectDefaults.day["29"]} value="29">
                            29
                        </option>
                        <option selected={selectDefaults.day["30"]} value="30">
                            30
                        </option>
                        <option selected={selectDefaults.day["31"]} value="31">
                            31
                        </option>
                    </select>
                    <select
                        className={cx(
                            signInStyles.form,
                            "select",
                            styles.select
                        )}
                        id="ship-date-year"
                        name="year"
                        onChange={handleSelectDate}
                    >
                        <option
                            value=""
                            disabled
                            selected={selectDefaults.year.empty}
                        >
                            {t("year", { ns: "date_time" })}
                        </option>
                        <option
                            selected={selectDefaults.year["2024"]}
                            value="2024"
                        >
                            2024
                        </option>
                        <option
                            selected={selectDefaults.year["2025"]}
                            value="2025"
                        >
                            2025
                        </option>
                        <option
                            selected={selectDefaults.year["2026"]}
                            value="2026"
                        >
                            2026
                        </option>
                        <option
                            selected={selectDefaults.year["2027"]}
                            value="2027"
                        >
                            2027
                        </option>
                        <option
                            selected={selectDefaults.year["2028"]}
                            value="2028"
                        >
                            2028
                        </option>
                        <option
                            selected={selectDefaults.year["2029"]}
                            value="2029"
                        >
                            2029
                        </option>
                        <option
                            selected={selectDefaults.year["2030"]}
                            value="2030"
                        >
                            2030
                        </option>
                    </select>
                </div>
                <ButtonPrimary
                    classes={styles["submit-button"]}
                    isDisabled={!hasSelectedDate || isLoading}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    text={t("submit", { ns: "common" })}
                    type="submit"
                />
            </div>
        </Modal>
    );
};
